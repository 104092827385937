import { IMenuItem, IMenuResponse, ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useState } from 'react';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import isSearchOpen from '../../store/search';
import { theme, Theme } from '../../store/theme';
import ThemeToggle from '../Header/ThemeToggle';
import Img from '../Img';
import Portal from '../Portal';
import styles from './NavMenu.module.scss';
import Submenu from './Submenu';
import Logo from '../Logo';

type NavMenuProps = {
  onClose: () => void,
  data: IMenuResponse,
};

const NavMenu = ({ onClose, data }: NavMenuProps) => {
  const [activeSubmenu, setActiveSubmenu] = useState<null | ISubMenu>(null);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const [startSubmenuClosing, setStartSubmenuClosing] = useState<boolean>(false);
  const pathname = usePathname();
  const appTheme = useAtomValue<Theme>(theme);
  const setShowSearch = useSetAtom(isSearchOpen);

  const isMobile = (deviceSize <= ScreenSize.MOBILE);

  const hideSubmenu = () => {
    if (isMobile) {
      setActiveSubmenu(null);

      return;
    }

    setStartSubmenuClosing(true);
    setTimeout(() => {
      setActiveSubmenu(null);
      setStartSubmenuClosing(false);
    }, 510);
  };

  const handleChangeMenu = (menuItem: ISubMenu) => {
    if (activeSubmenu
        && activeSubmenu.type === 'submenu'
        && menuItem.type === 'submenu'
        && !isMobile
    ) {
      setStartSubmenuClosing(true);
      setTimeout(() => {
        setStartSubmenuClosing(false);
        setActiveSubmenu(menuItem);
      }, 510);
    } else {
      setActiveSubmenu(menuItem);
    }
  };

  return (
    <Portal className={styles.menu}>
      <div className={styles.header}>
        <Link
          href='/'
          aria-label='Get back to main page'
          className={styles.logo}
        >
          <Logo
            width={100}
            height={32}
          />
        </Link>
        <button
          type='button'
          className={styles.closeButton}
          onClick={onClose}
        >
          <svg>
            <use
              xlinkHref='/media/menuClose.svg#menuCloseSVG'
              href='/media/menuClose.svg#menuCloseSVG'
            />
          </svg>
        </button>
      </div>
      <aside className={classnames(styles.sidebar, { [styles.submenu_active]: activeSubmenu })}>
        <nav className={styles.sidebar__menu}>
          {data.content.map(menuItem => (
            menuItem.type === 'menu-item' ? (
              <Link
                key={`${menuItem.id}/${menuItem.slug}`}
                href={(menuItem as IMenuItem).slug === '' ? '/' : `/${(menuItem as IMenuItem).slug}`}
                className={classnames(styles.menuLink, {
                  [styles.menuLink_active]: (pathname === '/' && (menuItem as IMenuItem).slug === '')
                    || ((menuItem as IMenuItem).slug !== '' && pathname.includes(`/${(menuItem as IMenuItem).slug}`)),
                })}
              >
                {menuItem.title}
              </Link>
            )
              : (
                <>
                  <button
                    key={menuItem.id}
                    type='button'
                    aria-label={`Show ${menuItem.title} submenu`}
                    onClick={() => (activeSubmenu === menuItem ? hideSubmenu()
                      : handleChangeMenu(menuItem))}
                    className={classnames(styles.menuLink, {
                      [styles.menuLink_active]: (activeSubmenu === menuItem)
                        || (menuItem.type === 'submenu' && !!menuItem.content.find(item => item.slug && pathname.includes(item.slug))),
                      [styles.menuLink_submenu_active]: activeSubmenu === menuItem,
                    })}
                  >
                    {menuItem.title}
                    <div className={styles.menuLink__icon}>
                      <svg>
                        <use
                          xlinkHref='/media/angle_down.svg#angleDown'
                          href='/media/angle_down.svg#angleDown'
                        />
                      </svg>
                    </div>
                  </button>
                  {menuItem.content.length && isMobile && (
                    <Submenu
                      activeSubmenu={activeSubmenu}
                      menuItem={menuItem}
                      key={menuItem.id}
                    />
                  )}
                </>
              )
          ))}
        </nav>
        <footer className={styles.footer}>
          <div className={styles.themeMode}>
            Dark
            <ThemeToggle />
            Light
          </div>
          <button
            type='button'
            className={styles.searchButton}
            name='Search'
            onClick={() => setShowSearch(true)}
            aria-label='search'
          >
            <svg>
              <use
                xlinkHref='/media/search.svg#searchSVG'
                href='/media/search.svg#searchSVG'
              />
            </svg>
          </button>
        </footer>
      </aside>
      <div className={classnames(styles.main, {
        [styles.main_visible]: activeSubmenu !== null,
        [styles.main_hidden]: startSubmenuClosing,
      })}
      >
        {!isMobile && (
          <>
            <div className={classnames(styles.illustration, {
              [styles.illustration_hidden]: (activeSubmenu !== null && activeSubmenu?.title !== 'Career' && !startSubmenuClosing),
            })}
            >
              <div className={classnames(styles.image, {
                [styles.image_contacts]: pathname.includes('contacts'),
              })}
              >
                <Img
                  src={activeSubmenu?.title === 'Career' || pathname.includes('career')
                    ? `/media/menu/Career_${appTheme}.webp`
                    : pathname.includes('blog')
                      ? `/media/menu/Blog_${appTheme}.webp`
                      : pathname.includes('cases')
                        ? `/media/menu/cases_${appTheme}.webp`
                        : pathname.includes('company')
                          ? `/media/menu/Company_${appTheme}.webp`
                          : pathname.includes('contacts')
                            ? `/media/menu/contacts_${appTheme}.webp`
                            : `/media/menu/home_${appTheme}.webp`}
                  alt='sub menu image'
                />
              </div>
            </div>
            {activeSubmenu?.content.length && (
              <div className={classnames(styles.main__content, {
                [styles.main__content_visible]: activeSubmenu !== null,
                [styles.main__content_hidden]: startSubmenuClosing,
              })}
              >
                <Submenu
                  activeSubmenu={activeSubmenu}
                  menuItem={activeSubmenu}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Portal>
  );
};

export default NavMenu;
