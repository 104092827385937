import { IMenuItem, ISubMenu } from '@sapientpro/sapientpro-data-models';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { theme } from '../../../store/theme';
import Img from '../../Img';

import styles from './Submenu.module.scss';
import Button, { ButtonVariants } from '../../Button';
import clientScreenSize, { ScreenSize } from '../../../store/deviceScreenSize';

const SubmenuItem = ({ submenuItem }: { submenuItem: IMenuItem | ISubMenu }) => {
  const appTheme = useAtomValue(theme);

  return (
    <div className={styles.submenu__sublist__item}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          {submenuItem?.type === 'submenu' && submenuItem.menuIcon && (
            <Img
              src={submenuItem.menuIcon[appTheme].url}
              alt={submenuItem.menuIcon[appTheme].alternativeText}
            />
          )}
        </div>
      </div>
      <div className={styles.submenu__sublist__item__text}>
        {submenuItem?.slug ? (
          <Link
            href={`/${submenuItem.slug}`}
            className={styles.title}
          >
            {submenuItem.title}
          </Link>
        ) : (
          <p className={styles.title}>{submenuItem.title}</p>
        )}
      </div>
    </div>
  );
};

const ExploreSection = () => {
  const deviceSize = useAtomValue<ScreenSize>(clientScreenSize);
  const appTheme = useAtomValue(theme);
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
  }, []);

  return isMount ? (
    <div className={styles.exploreAll}>
      <div className={styles.icon}>
        <Img
          src={`/media/industry_geometry/Geometry_2_${appTheme}.svg`}
          alt='illustration'
        />
      </div>
      <h3 className={styles.title}>
        Explore All Our Services
      </h3>
      <p className={styles.subTitle}>
        Discover full range of expertise services designed to support your.
      </p>
      <Link href='/services'>
        <Button
          variant={ButtonVariants.TRANSPARENT}
          className={styles.allServicesButton}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={deviceSize <= ScreenSize.DESKTOP_SMALL ? { width: 24, height: 24 } : { width: 32, height: 32 }}
          size={deviceSize <= ScreenSize.DESKTOP_SMALL ? 'normal' : 'big'}
        >
          All Services
        </Button>
      </Link>
    </div>
  ) : null;
};

const Submenu = ({ data, className }: { data: ISubMenu | null; className: string }) => {
  const COLUMN_COUNT = 2;
  const groupList = (originalArray: ISubMenu, numberOfGroups: number) => {
    const groupedArray = [];
    const groupSize = Math.ceil(originalArray.content.length / numberOfGroups);
    for (let i = 0; i < originalArray.content.length; i += groupSize) {
      groupedArray.push(originalArray.content.slice(i, i + groupSize));
    }
    return groupedArray;
  };

  return (
    <section
      className={classNames(styles.submenu, className, {
        [styles.isVisible]: !!data?.content?.length,
      })}
    >
      <ul className={styles.submenu__mainlist}>
        <li>
          <ExploreSection />
        </li>
        {data
          && groupList(data, COLUMN_COUNT).map((column) => (
            <li
              key={`${column[0].id}/${column[0].title}`}
              className={styles.submenu__mainlist__item}
            >
              <ul className={styles.submenu__sublist}>
                {column.map((navItem) => (
                  <li key={`${navItem.id}/${navItem.title}`}>
                    {navItem.type === 'submenu' ? (
                      <SubmenuItem submenuItem={navItem} />
                    ) : navItem.slug ? (
                      <Link
                        href={`/${navItem.slug}`}
                        className={styles.submenu__sublist__item}
                      >
                        <span className={styles.title}>{navItem.title}</span>
                      </Link>
                    ) : (
                      <span className={styles.submenu__sublist__item}>{navItem.title}</span>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default Submenu;
