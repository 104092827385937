import { ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import React, { useRef } from 'react';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import styles from './Submenu.module.scss';
import SubmenuItem from './SubmenuItem';
import Button, { ButtonVariants } from '../Button';

type NavMenuProps = {
  activeSubmenu: ISubMenu | null;
  menuItem: ISubMenu;
};

const Submenu = ({ activeSubmenu, menuItem }: NavMenuProps) => {
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);

  const isMobile = deviceSize <= ScreenSize.MOBILE;

  const submenuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={styles.submenuContainer}
      ref={submenuRef}
      style={{
        height: !isMobile
          ? '100%'
          : activeSubmenu?.id === menuItem.id
            ? submenuRef.current?.scrollHeight
            : '0px',
      }}
    >
      <div
        className={classnames(styles.submenu, {
          [styles.submenuActive]: activeSubmenu?.id === menuItem?.id,
          [styles.isCareerBg]: !isMobile && activeSubmenu?.title === 'Career',
        })}
      >
        {menuItem.content.map((submenuItem) => (
          <SubmenuItem
            key={submenuItem.id}
            submenuItem={submenuItem as ISubMenu}
          />
        ))}
      </div>
      {menuItem.title === 'Services' && (
        <Link href='/services'>
          <Button
            variant={ButtonVariants.TRANSPARENT}
            className={styles.allServicesButton}
            icon={(
              <svg>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
            )}
            iconPosition='right'
            iconSize={
              isMobile
                ? { width: 24, height: 24 }
                : { width: 32, height: 32 }
            }
            size={isMobile ? 'normal' : 'big'}
          >
            All Services
          </Button>
        </Link>
      )}
    </div>
  );
};

export default Submenu;
